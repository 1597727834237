
















import { Component, Vue } from "vue-property-decorator";
import Project from "@/app/shared/models/project.model";

@Component({
  async beforeRouteUpdate(to, from, next): Promise<void> {
    const instance = this as ProjectsView;
    if (to.name === "settings-projects" && instance.projects.length) {
      const [firstProject] = instance.projects;
      next({
        name: instance.projectRoute,
        params: { settingsProject: firstProject.id?.toString() || "" },
      });
    } else {
      next();
    }
  },
})
export default class ProjectsView extends Vue {
  private get projects(): Array<Project> {
    return this.$store.getters["projectsModule/projects"];
  }

  private projectRoute = "settings-project-selected";

  created(): void {
    if (!this.$route.params.project) {
      const [firstProject] = this.projects;
      if (firstProject && firstProject.id)
        this.$router.push({
          name: "settings-project-selected",
          params: { settingsProject: firstProject.id.toString() },
        });
    }
  }

  // created(): void {
  //   const [firstProject] = this.projects;
  //   if (
  //     firstProject.id &&
  //     this.$route.params.project === firstProject.id?.toString()
  //   )
  //     return;
  //   this.$router.push({
  //     name: this.projectRoute,
  //     params: { project: firstProject.id?.toString() },
  //   });
  // }
}
